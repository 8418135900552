import React from "react"
import style from "./Page.module.scss"
import { Row, Col } from "react-bootstrap"
import FilterSideBar from "@components/FilterComponent/Filter"
import { SimplifiedBookmakerBonus } from "types/bookmakerBonuses"
import BookmakersBanner from "@components/Betting/bookmakersBanner/BookmakersBanner"
import SideBookmakers from "@components/Betting/sideBookmakers/SideBookmakers"
import { NavigationProps } from "@components/Navigation"
import BookmakerColumn from "@components/BookmakerColumn"

type Props = {
  body: JSX.Element
  navigationProps?: NavigationProps
  bookmakerBonuses?: SimplifiedBookmakerBonus[]
  showRightColumn?: boolean
  showCenterColumn?: boolean
  colDimensions?: { md: number; lg: number; xl: number }
}

export function Page({
  body,
  navigationProps,
  bookmakerBonuses,
  showRightColumn = true,
  showCenterColumn = true,
  colDimensions = { md: 12, lg: 8, xl: 8 },
}: Props) {
  return (
    <Row className={style.pageContainer + " mainPageContainer"}>
      {navigationProps || bookmakerBonuses ? (
        <Col md={2} lg={2} xl={2} className={style.filterContainer}>
          {showRightColumn && navigationProps && <FilterSideBar navigationProps={navigationProps} />}
          {!showRightColumn && bookmakerBonuses && <BookmakerColumn bookmakerBonuses={bookmakerBonuses} />}
        </Col>
      ) : null}
      <Col md={colDimensions.md} lg={colDimensions.lg} xl={colDimensions.xl} className={style.bodyContainer}>
        {showCenterColumn && bookmakerBonuses ? <BookmakersBanner bookmakerBonuses={bookmakerBonuses} /> : null}
        <Row xs={12} className={style.body}>
          {body}
        </Row>
      </Col>
      {showRightColumn ? (
        <Col className={style.sideBookmakers}>
          {bookmakerBonuses && <SideBookmakers bookmakerBonuses={bookmakerBonuses} />}
        </Col>
      ) : null}
    </Row>
  )
}
