import React from "react"
import { GlobalSearchResults, GlobalSearchResultsProps } from "./GlobalSearchResults"
import { useSearch } from "./searchHook"
import { useRouter } from "next/router"
import { getNewsPageUrl } from "../../../utils/urls"
import { LocalizedNews } from "types/news"
import { NewsAPI } from "../../../apis/NewsAPI"

const NewsResult = ({ data, closeGlobalSearch }: { data: LocalizedNews; closeGlobalSearch: () => void }) => {
  const router = useRouter()
  const { title } = data
  const newsUrl = getNewsPageUrl(data!)

  const open = () => {
    router.push(newsUrl)
    closeGlobalSearch()
  }

  return (
    <div className="global-search-news" onClick={open}>
      <div className="text-container">
        <span className="title news">{title}</span>
      </div>
    </div>
  )
}

export const NewsResults = ({
  search,
  ...props
}: {
  search: string
} & Omit<GlobalSearchResultsProps<LocalizedNews>, "loading" | "data" | "Result">) => {
  const { loading, data } = useSearch(NewsAPI.search, search)

  return <GlobalSearchResults loading={loading} data={data} Result={NewsResult} {...props} />
}
