import React from "react"
import LoadingOutlined from "@ant-design/icons/lib/icons/LoadingOutlined"

export type GlobalSearchResultsProps<Data> = {
  loading: boolean
  data: Data[] | null
  Result: React.FunctionComponent<{
    data: Data
    closeGlobalSearch: () => void
  }>
  closeGlobalSearch: () => void
}

export const GlobalSearchResults = <Data,>({
  loading,
  data,
  Result,
  closeGlobalSearch,
}: {
  loading: boolean
  data: Data[] | null
  Result: React.FunctionComponent<{
    data: Data
    closeGlobalSearch: () => void
  }>
  closeGlobalSearch: () => void
}) => {
  if (loading) {
    return (
      <div className="global-search-results-loading-container">
        <LoadingOutlined style={{ fontSize: 24 }} />
      </div>
    )
  }

  if (!data || !data.length) {
    return <div className="global-search-results-no-results-container">Nessun risultato</div>
  }

  return (
    <div className="global-search-results-list">
      {data.map((row, index) => {
        return <Result key={index} data={row} closeGlobalSearch={closeGlobalSearch} />
      })}
    </div>
  )
}
