import { Row } from "react-bootstrap"
import React from "react"
import { SimplifiedBookmakerBonus } from "types/bookmakerBonuses"
import Image from "next/image"
import { TextButtonBonus } from "@components/UI/TextButtonBonus"
import starIcon from "@assets/icons/bookmakers/icon-star.png"
import style from "./SideBookmakers.module.scss"

type Props = {
  bookmakerBonuses: SimplifiedBookmakerBonus[]
}

export default function SideBookmakers({ bookmakerBonuses }: Props) {
  return (
    <div className={style.container}>
      {bookmakerBonuses
        .filter((b) => b.showOnHome)
        .slice(0, 3)
        .map((b) => {
          return (
            <Row md={12} key={b.id} className={style.card}>
              <div className={style.body}>
                <div className={style.imageContainer}>
                  <div className={style.image}>
                    <Image src={b.image!.path!} alt={b.name} width={100} height={50} />
                  </div>
                  <div className={style.stars}>
                    <Image width={20} height={20} src={starIcon} alt="star" />
                    <div className={style.condition}>
                      <span>{b.condition?.split(" ").pop()}</span>
                    </div>
                  </div>
                </div>
                <div className={style.review}>
                  <div className={style.text}>
                    Bonus massimo
                    <br />
                    <span>{b.bonus}€</span>
                    {b.extra ? <span className={style.extra}> + {b.extra}</span> : null}
                  </div>
                  <div className={style.button}>
                    <TextButtonBonus type="green" href={b.url!} text="Verifica" />
                  </div>
                </div>
              </div>
            </Row>
          )
        })}
    </div>
  )
}
