import Link from "next/link"
import React from "react"

export const TextButtonBonus = ({
  text,
  href,
  type = "yellow",
  target,
  rel,
}: {
  text: string
  href: string
  rel?: string
  type?: "yellow" | "gray" | "white" | "green"
  target?: string
}) => {
  return (
    <div>
      <div className={`text-button-bonus-cc ${type}`}>
        <Link href={href} passHref legacyBehavior>
          <a target={target} rel={rel}>
            {text}
          </a>
        </Link>
      </div>
    </div>
  )
}
