import React, { useEffect, useState } from "react"
import { Row, Col, Container } from "react-bootstrap"
import style from "./style.module.scss"
import { useRouter } from "next/router"
import Link from "next/link"

type NavLink = {
  id: string
  active: boolean
  name: string
  href: string
}

export default function NewNavigation() {
  const [links, setLinks] = useState<NavLink[]>([])
  const router = useRouter()

  useEffect(() => {
    updateLinks(router.pathname)
  }, [router.pathname])

  const updateLinks = (currentPath: string) => {
    let updatedLinks: NavLink[] = []

    if (currentPath.startsWith("/statistiche")) {
      updatedLinks = [
        { id: "squadre", active: false, name: "Squadre", href: "/statistiche#squadre" },
        { id: "classifica", active: false, name: "Classifica", href: "/statistiche#classifica" },
        { id: "giornata", active: false, name: "Giornata", href: "/statistiche#giornata" },
        { id: "marcatori", active: false, name: "Marcatori", href: "/statistiche#marcatori" },
      ]
    } else if (currentPath.startsWith("/scommesse") || currentPath.startsWith("/news")) {
      updatedLinks = [
        { id: "pronostici", active: false, name: "Pronostici", href: "/scommesse/quote-pronostici" },
        { id: "quote-maggiorate", active: false, name: "Quote maggiorate", href: "/scommesse/quote-maggiorate" },
        { id: "bonus", active: false, name: "Bonus", href: "/scommesse/bonus" },
        { id: "bookmakers", active: false, name: "Bookmakers", href: "/scommesse/bookmakers" },
        { id: "guide", active: false, name: "Guide", href: "/scommesse/guide" },
        { id: "betmaker", active: false, name: "Betmaker", href: "/scommesse/betmaker" },
      ]
    }

    updatedLinks.forEach((link) => {
      link.active = currentPath.includes(link.href)
    })

    if (currentPath.startsWith("/news")) {
      updatedLinks[0].active = true
    }

    setLinks(updatedLinks)
  }

  const handleLinkClick = (id: string) => {
    setLinks(
      links.map((link) => ({
        ...link,
        active: link.id === id,
      })),
    )
  }

  return (
    <div className={style.mainContainer}>
      <Container className={style.container}>
        <Row className={style.mobileContainer}>
          <Row className={style.linkContainer}>
            <Col xs={3} md={4} lg={1} className={style.link}>
              <Link passHref href={"/statistiche"}>
                <a className={router.pathname.includes("statistiche") ? style.active : ""}>Statistiche</a>
              </Link>
            </Col>
            <Col className={style.splitter} xs={1}>
              |
            </Col>
            <Col xs={3} md={4} lg={1} className={style.link}>
              <Link passHref href={"/scommesse"}>
                <a className={router.pathname.includes("scommesse") ? style.active : ""}>Scommesse</a>
              </Link>
            </Col>
            <Col className={style.splitter} xs={1}>
              |
            </Col>
            <Col xs={4} md={4} lg={1} className={style.link}>
              <Link passHref href={"/live-risultati"}>
                <a className={router.pathname.includes("live-risultati") ? style.active : ""}>Live e Risultati</a>
              </Link>
            </Col>
          </Row>
        </Row>
        <Row className={style.desktopContainer}>
          <div className={style.linkContainer}>
            {links.map((l, i) => {
              let additionalClassName = ""
              if (l.name === "Quote maggiorate") {
                additionalClassName = style.longWord
              }
              return (
                <div className={`${style.link} ${additionalClassName}`} key={i} onClick={() => handleLinkClick(l.id)}>
                  <Link passHref href={l.href}>
                    <a className={l.active ? style.active : ""}>{l.name}</a>
                  </Link>
                </div>
              )
            })}
          </div>
        </Row>
      </Container>
    </div>
  )
}
