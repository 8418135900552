import Image from "next/image"
import { SimplifiedBookmakerBonus } from "../../../types/bookmakerBonuses"
import style from "./style.module.scss"
import Link from "next/link"

type Props = {
  bookmakerBonuses: SimplifiedBookmakerBonus[]
}

export default function BookmakerColumn({ bookmakerBonuses }: Props) {
  return (
    <div className={style.bookmakerColumnContainer}>
      <div className={style.title}>
        <h3>Bookmakers</h3>
      </div>
      {bookmakerBonuses.map((bookmakerBonus, index) => (
        <div key={index} className={style.bookmakerColumn}>
          <div className={style.image}>
            {bookmakerBonus.image?.path && (
              <Image src={bookmakerBonus.image?.path} alt={bookmakerBonus.name} width={72} height={32} />
            )}
          </div>
          <div className={style.name}>
            <Link passHref href={`/scommesse/bookmakers/${bookmakerBonus.calciocomSlug}`}>
              <a>{bookmakerBonus.name}</a>
            </Link>
          </div>
        </div>
      ))}
    </div>
  )
}
