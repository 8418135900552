import axios from "axios"
import * as APIConstants from "constants/apis"
import * as LanguageConstants from "constants/languages"

const api = axios.create()

export default api

const defaultCalcioComHeaders = {
  [APIConstants.LANGUAGE_HEADER]: LanguageConstants.DEFAULT_LANGUAGE,
}

export const importFelixApi = axios.create({
  baseURL: "https://aws.felix.kama.sport",
  headers: {
    authorization: "Basic a2FtYTgyNjM5MjUzOTEyNzpTbkd1bzZBZVlFa2Z2YUd6",
  },
})

// Aggiungi un interceptor per le richieste
importFelixApi.interceptors.request.use(
  function (config) {
    // Logga la richiesta
    console.log("Richiesta:", config)
    return config
  },
  function (error) {
    return Promise.reject(error)
  },
)

export const calciocomApi = axios.create({
  baseURL: process.env.NEXT_PUBLIC_API_CALCIO_COM_BACKEND_BASE_URL,
  headers: {
    ...defaultCalcioComHeaders,
  },
  auth: {
    username: process.env.BASIC_AUTH_USERNAME as string,
    password: process.env.BASIC_AUTH_PASSWORD as string,
  },
})

export const calciocomApiFrontend = axios.create({
  withCredentials: true,
  baseURL: process.env.NEXT_PUBLIC_API_CALCIO_COM_BACKEND_BASE_URL,
  headers: {
    ...defaultCalcioComHeaders,
  },
})
