import Head from "next/head"
import React from "react"
import { BackofficeContainer } from "@components/PageLayout/Backoffice/BackofficeContainer"
import Script from "next/script"
import "react-quill/dist/quill.snow.css"

export const BackofficePageLayout = ({ children, title }: { children: any; title: string }) => {
  const keywords = ""

  const seoTitle = `${title ? `${title} | Calcio.com` : `Calcio.com`}`

  return (
    <>
      <Head>
        <title>{seoTitle}</title>
        <meta name="keywords" content={keywords} />
        <meta charSet="utf-8" />
        <meta httpEquiv="content-type" content="text/html; charset=utf-8" />
        <meta httpEquiv="content-language" content="it" />
        <meta property="og:locale" content="it_IT" />
        <meta property="og:title" content={`${seoTitle}`} />
        <meta property="og:description" content={""} />
        <meta property="og:type" content={"website"} />
        <meta property="og:image" content={"https://s.hs-data.com/bilder/calcio_fb.png"} />
        <meta property="og:url" content={"https://www.calcio.com/"} />
        <meta property="og:site_name" content="calcio.com" />
        <meta property="article:publisher" content="https://www.facebook.com/Calciocom" />
        <meta property="twitter:card" content="summary" />
        <meta property="twitter:site" content="@Calciocomoff" />
        <meta name="robots" content="noindex, nofollow" />
        <meta property="fb:admins" content="100000496658474" />
        <meta property="fb:app_id" content="219167318207572" />
        <meta property="fb:pages" content="475650659136004" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0, user-scalable=yes, minimum-scale=1" />
        <link rel="alternate" type="application/rss+xml" title="RSS 2.0" href="https://calcio.com/rss" />
        <link rel="icon" href="/favicon.ico" />
        <link rel="preconnect" href="https://stats.g.doubleclick.net" />
        <link rel="preconnect" href="https://onetag-sys.com" />
        <link rel="preconnect" href="https://boot.pbstck.com" />
        <link rel="preconnect" href="https://quantcast.mgr.consensu.org" />
        <link rel="preconnect" href="https://www.google-analytics.com" />
        <link rel="preconnect" href="https://hbagency.it" />
        <link rel="preconnect" href="https://www.hbagency.it" />
        <link rel="preconnect" href="https://cdn.pbstck.com" />
        <link rel="preconnect" href="https://www.googletagmanager.com" />
        <link rel="preconnect" href="https://cdnjs.cloudflare.com" />
        <link rel="preconnect" href="https://services.brid.tv" />
        <link
          href="https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600;700&display=swap"
          rel="stylesheet"
        />
      </Head>
      <Script
        id={"google-tag-manager"}
        dangerouslySetInnerHTML={{
          __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','${process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_KEY}');`,
        }}
      />
      <BackofficeContainer content={children} />
    </>
  )
}
