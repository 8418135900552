import axios, { CancelToken } from "axios"
import { useEffect, useState } from "react"
import { get } from "lodash"

function makeDeplyIfNeeded(useDelay: boolean): Promise<void> {
  return new Promise((resolve) => {
    if (useDelay) {
      setTimeout(() => {
        resolve()
      }, 400)
    } else {
      resolve()
    }
  })
}

export const useSearch = <T,>(
  callApi: (search: string, cancelToken: CancelToken) => Promise<T[]>,
  search: string,
  arrayPath = "",
  postprocessing: any | null = null,
) => {
  const [state, setState] = useState<{ loading: boolean; data: T[] | null }>({ loading: true, data: null })
  const { loading, data } = state

  useEffect(() => {
    if (search) {
      const source = axios.CancelToken.source()
      let cancelled = false
      // eslint-disable-next-line prefer-const
      let useDelay = true

      // if(loading){
      //   useDelay = true
      // }

      setState({ loading: true, data: null })

      makeDeplyIfNeeded(useDelay)
        .then(() => {
          if (cancelled) {
            // eslint-disable-next-line no-throw-literal
            throw { isCancelled: true }
          }
          return callApi(search, source.token)
        })
        .then((response) => {
          let data = response
          if (arrayPath) {
            data = get(response, arrayPath)
          }
          if (postprocessing) {
            return postprocessing(data)
          }
          return data
        })
        .then((data) => {
          if (cancelled) {
            return
          }
          setState({ loading: false, data: data })
        })
        .catch((err) => {
          if (err.isCancelled || axios.isCancel(err)) {
            return
          }
          console.log(err)
        })

      return () => {
        cancelled = true
        source.cancel()
      }
    } else {
      setState({ loading: false, data: [] })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callApi, search, arrayPath])

  return {
    loading,
    data,
  }
}
