import React from "react"
import { GlobalSearchResults, GlobalSearchResultsProps } from "./GlobalSearchResults"
import { useSearch } from "./searchHook"
import { PlayerAPI } from "../../../apis/PlayerAPI"
import * as ImageConstants from "../../../constants/images"
import { LocalizedPlayer } from "types/player"
import { useRouter } from "next/router"
import { getPlayerPageUrl } from "../../../utils/urls"
import Image from "next/image"

const PlayerResult = ({ data, closeGlobalSearch }: { data: LocalizedPlayer; closeGlobalSearch: () => void }) => {
  const router = useRouter()
  const { name, image, currentTeam } = data
  const playerUrl = getPlayerPageUrl(data.calciocomSlug!)

  const team = currentTeam?.name || null

  const open = () => {
    router.push(playerUrl)
    closeGlobalSearch()
  }

  return (
    <div className="global-search-player" onClick={open}>
      <div className="image-container">
        <Image
          src={image?.path || ImageConstants.PLAYER_MISSING_IMAGE_PATH}
          alt={name}
          title={name}
          className={"rounded-circle person-image-player"}
          layout={"intrinsic"}
          width={42}
          height={42}
        />
      </div>
      <div className="text-container">
        <span className="name">{name}</span>
        <span className="team">{team}</span>
      </div>
    </div>
  )
}

export const PlayerResults = ({
  search,
  ...props
}: {
  search: string
} & Omit<GlobalSearchResultsProps<LocalizedPlayer>, "loading" | "data" | "Result">) => {
  const { loading, data } = useSearch(PlayerAPI.search, search)

  return <GlobalSearchResults loading={loading} data={data} Result={PlayerResult} {...props} />
}
