import { calciocomApi, calciocomApiFrontend } from "./axiosConfig"
import * as APIConstants from "../constants/apis"
import { generatePath } from "react-router-dom"
import { LocalizedGroup } from "../types/group"
import { LocalizedGroupTeamStanding, TeamBettingParameterStandingFlat } from "../types/groupTeamStanding"
import { createCancelTokenObject } from "./axiosUtils"

export const GroupAPI = {
  getGroup: async function (groupId: number): Promise<LocalizedGroup> {
    const response = await calciocomApi.request({
      url: generatePath(APIConstants.GROUPS_V1_GET_BY_ID, {
        id: groupId,
      }),
      method: APIConstants.GET,
    })

    return response.data.group
  },
  getTeamStandings: async function (
    groupId: number,
    turnId: number | undefined = undefined,
  ): Promise<LocalizedGroupTeamStanding[]> {
    const response = await calciocomApi.request({
      url: generatePath(APIConstants.GROUPS_V1_GET_TEAM_STANDINGS_BY_ID, {
        id: groupId,
      }),
      params: {
        turnId: turnId,
      },
      method: APIConstants.GET,
    })

    return response.data.standings
  },
  getIsStandingsAvailable: async function (groupId: number, turnId: number | undefined = undefined): Promise<boolean> {
    const response = await calciocomApi.request({
      url: generatePath(APIConstants.GROUPS_V1_GET_IS_STANDINGS_AVAILABLE, {
        id: groupId,
      }),
      params: {
        turnId: turnId,
      },
      method: APIConstants.GET,
    })

    return response.data.available
  },
  getHomeTeamStandings: async function (
    groupId: number,
    turnId: number | undefined = undefined,
  ): Promise<LocalizedGroupTeamStanding[]> {
    const response = await calciocomApiFrontend.request({
      url: generatePath(APIConstants.GROUPS_V1_GET_HOME_TEAM_STANDINGS_BY_ID, {
        id: groupId,
      }),
      params: {
        turnId: turnId,
      },
      method: APIConstants.GET,
      // cancelToken: cancelTokenObject[this.getHomeTeamStandings.name].handleRequestCancellation().token,
    })

    return response.data.standings
  },
  getTeamBettingParameterStandings: async function (groupId: number): Promise<TeamBettingParameterStandingFlat[]> {
    const response = await calciocomApi.request({
      url: generatePath(APIConstants.GROUPS_V1_GET_TEAM_BETTING_PARAMETER_STANDINGS_BY_ID, {
        id: groupId,
      }),
      method: APIConstants.GET,
    })

    return response.data.standings
  },
  getTeamBettingParameterStandingsFrontend: async function (
    groupId: number,
  ): Promise<TeamBettingParameterStandingFlat[]> {
    const response = await calciocomApiFrontend.request({
      url: generatePath(APIConstants.GROUPS_V1_GET_TEAM_BETTING_PARAMETER_STANDINGS_BY_ID, {
        id: groupId,
      }),
      method: APIConstants.GET,
    })

    return response.data.standings
  },
}

const cancelTokenObject = createCancelTokenObject(GroupAPI)
