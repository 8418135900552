import Head from "next/head"
import React from "react"
import { RSS, SEO } from "../../../types/next.js"
import { NEWS_SLUG_MAP } from "../../../constants/news"

export const CalcioComHead = ({
  seo,
  title,
  rss,
  prev,
  next,
}: {
  seo?: SEO
  rss?: RSS
  title: string
  prev?: string
  next?: string
}) => {
  const baseUrl = process.env.NEXT_PUBLIC_BASE_URL || "https://calcio.com"

  let keywords =
    "Calcio, News, notizie, Risultati, Diretta scritta, Statistiche, Campionato europeo, Campionato mondiale, Bundesliga (GER), Inghilterra, Coppa, Italia, Spagna, Francia, Austria, Svizzera, leggende, Giocatori, Squadra, Squadre, Rosa"

  if (seo?.keywords) {
    if (seo.keywords.length > 0) {
      keywords = seo.keywords.filter((k) => k && k.trim() != "").join(", ")
    }
  }

  const seoDescription = seo?.description
    ? seo.description
    : "Leggi le notizie aggiornate dal mondo del calcio, gli scoop calciomercato, e segui le dirette live. Non mancano statistiche complete su squadre e calciatori."
  const seoTitle = `${title ? `${title} | Calcio.com` : `Calcio.com: notizie calcio, dirette e statistiche complete`}`
  let indexFollow: string | undefined = undefined

  let index: string | undefined = undefined
  if (seo?.index === true) {
    index = "index"
  } else if (seo?.index === false) {
    index = "noindex"
  }
  let follow: string | undefined = undefined
  if (seo?.follow === true) {
    follow = "follow"
  } else if (seo?.follow === false) {
    follow = "nofollow"
  }
  if (index && follow) {
    indexFollow = `${index}, ${follow}`
  } else if (index) {
    indexFollow = `${index}`
  } else if (follow) {
    indexFollow = `${follow}`
  }
  let openGraphUrl = baseUrl
  if (seo?.url) {
    openGraphUrl = seo.url
  }
  if (seo?.canonicals?.length && seo.canonicals.length === 1) {
    openGraphUrl = seo.canonicals[0]
  }

  const newsTypeValuesWithAll: string[] = Object.keys(NEWS_SLUG_MAP)

  const rssLinks = []

  if (rss) {
    const competitionCalcioComSlug = rss.competitionCalcioComSlug
    const type = rss.type
    if (competitionCalcioComSlug) {
      if (type) {
        rssLinks.push(`${baseUrl}/rss/campionato/${competitionCalcioComSlug}/${type}`)
      } else {
        newsTypeValuesWithAll.forEach((type) => {
          rssLinks.push(`${baseUrl}/rss/campionato/${competitionCalcioComSlug}/${type}`)
        })
      }
    } else if (type) {
      rssLinks.push(`${baseUrl}/rss/${type}`)
    }
  } else {
    newsTypeValuesWithAll.forEach((type) => {
      rssLinks.push(`${baseUrl}/rss/${type}`)
    })
  }

  return (
    <Head>
      <title>{seoTitle}</title>
      <meta name="description" content={seoDescription} />
      <meta name="keywords" content={keywords} />
      <meta charSet="utf-8" />
      <meta httpEquiv="content-type" content="text/html; charset=utf-8" />
      <meta httpEquiv="content-language" content="it" />
      <meta property="og:locale" content="it_IT" />
      {indexFollow && <meta name={"robots"} content={indexFollow} />}
      <meta property="og:title" content={seoTitle} />
      <meta property="og:description" content={seoDescription} />
      <meta property="og:type" content={`${seo?.type ? seo.type : "website"}`} />
      <meta property="og:image" content={`${seo?.image ? seo.image : "https://s.hs-data.com/bilder/calcio_fb.png"}`} />
      <meta property="og:url" content={openGraphUrl} />
      <meta property="og:site_name" content="calcio.com" />
      <meta property="article:publisher" content="https://www.facebook.com/Calciocom" />
      <meta property="twitter:card" content="summary" />
      <meta property="twitter:site" content="@Calciocomoff" />
      <meta property="fb:admins" content="100000496658474" />
      <meta property="fb:app_id" content="219167318207572" />
      <meta property="fb:pages" content="475650659136004" />
      {seo?.publishedAt && <meta property="article:published_time" content={`${seo?.publishedAt}`} />}
      {seo?.publishedAt && <meta property="article:modified_time" content={`${seo?.publishedAt}`} />}
      <meta name="facebook-domain-verification" content="x73nor19756pg3a5afv91c2za1ferf" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0, user-scalable=yes, minimum-scale=1" />
      {rssLinks.map((rssLink, i) => {
        return <link key={i + 2500} rel="alternate" type="application/rss+xml" title="RSS 2.0" href={rssLink} />
      })}
      <link rel="icon" href="/favicon.ico" />
      <link rel="preconnect" href="https://www.google-analytics.com" />
      {prev ? <link rel="prev" href={`${process.env.NEXT_PUBLIC_BASE_URL}${prev}`} /> : null}
      {next ? <link rel="next" href={`${process.env.NEXT_PUBLIC_BASE_URL}${next}`} /> : null}
      {seo?.canonicals?.length &&
        seo.canonicals.map((canonical, i) => {
          return <link rel={"canonical"} href={`${process.env.NEXT_PUBLIC_BASE_URL}${canonical}`} key={i} />
        })}
    </Head>
  )
}
