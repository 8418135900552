import { calciocomApi, calciocomApiFrontend } from "./axiosConfig"
import * as APIConstants from "../constants/apis"
import { generatePath } from "react-router-dom"
import { CompetitionSeasonPlayerStatsStandingFlat } from "../types/competitionSeasonPlayerStats"
import { CompetitionSeasonTeamStatsStandingFlat } from "../types/competitionSeasonTeamStats"
import { createCancelTokenObject } from "./axiosUtils"
import { LocalizedTeam } from "../types/team"

export const CompetitionSeasonAPI = {
  getIdByCompetitionIdAndSeasonId: async function (competitionId: number, seasonId: number): Promise<number> {
    const response = await calciocomApi.request({
      url: generatePath(APIConstants.COMPETITION_SEASONS_V1_GET_ID_BY_COMPETITION_ID_AND_SEASON_ID, {
        competitionId: competitionId,
        seasonId: seasonId,
      }),
      method: APIConstants.GET,
    })
    return response.data.competitionSeasonId
  },
  getPlayerParameterStandings: async function (
    competitionSeasonId: number,
  ): Promise<CompetitionSeasonPlayerStatsStandingFlat[]> {
    const response = await calciocomApi.request({
      url: generatePath(APIConstants.COMPETITION_SEASONS_V1_GET_PLAYER_PARAMETER_STANDINGS_BY_ID, {
        id: competitionSeasonId,
      }),
      method: APIConstants.GET,
    })
    return response.data.standings
  },
  getTeamParameterStandings: async function (
    competitionSeasonId: number,
  ): Promise<CompetitionSeasonTeamStatsStandingFlat[]> {
    const response = await calciocomApi.request({
      url: generatePath(APIConstants.COMPETITION_SEASONS_V1_GET_TEAM_PARAMETER_STANDINGS_BY_ID, {
        id: competitionSeasonId,
      }),
      method: APIConstants.GET,
    })
    return response.data.standings
  },
  getHomeTeamParameterStandings: async function (
    competitionSeasonId: number,
  ): Promise<CompetitionSeasonTeamStatsStandingFlat[]> {
    const response = await calciocomApiFrontend.request({
      url: generatePath(APIConstants.COMPETITION_SEASONS_V1_GET_HOME_TEAM_PARAMETER_STANDINGS_BY_ID, {
        id: competitionSeasonId,
      }),
      method: APIConstants.GET,
      cancelToken: cancelTokenObject[this.getHomeTeamParameterStandings.name].handleRequestCancellation().token,
    })
    return response.data.standings
  },
  getTeamsByCompetitionSeason: async function (competitionSeasonId: number): Promise<LocalizedTeam[]> {
    const response = await calciocomApiFrontend.request({
      url: generatePath(APIConstants.COMPETITION_SEASONS_V1_GET_TEAMS_BY_ID, {
        id: competitionSeasonId,
      }),
      method: APIConstants.GET,
    })
    return response.data.teams
  },
}

const cancelTokenObject = createCancelTokenObject(CompetitionSeasonAPI)
