import Image from "next/image"
import calciocomLogo from "@assets/images/calcio-logo-512.png"
import { useRouter } from "next/router"
import classNames from "classnames"
import Link from "next/link"
import React, { useState } from "react"
import { Container } from "react-bootstrap"
import { CloseOutlined, MenuOutlined, SearchOutlined } from "@ant-design/icons"
import { NavigationProps } from "@components/Navigation"
import { GlobalSearchInput } from "@components/Search/GlobalSearchInput"
import FilterSideBar from "@components/FilterComponent/Filter"

export const Header = ({ navigationProps }: { navigationProps?: NavigationProps }) => {
  const [showBurgerMenu, setShowBurgerMenu] = useState<boolean>(false)
  const [showSearchMenu, setShowSearchMenu] = useState<boolean>(false)

  const router = useRouter()

  const isHomePageOrLiveResults = router.pathname === "/" || router.pathname === "/live-risultati"
  const containerClassName = isHomePageOrLiveResults ? "headerContainer" : ""

  const renderMenuItem = (label: string, url: string, otherClasses: string) => {
    const isActive = router.pathname.includes(url)

    return (
      <li className={classNames("hs-menu--item", isActive && "hs-menu--item-active", otherClasses)}>
        <Link href={url}>
          <a title={label}>{label}</a>
        </Link>
      </li>
    )
  }

  const renderMenu = (otherClasses = "") => {
    return (
      <>
        {renderMenuItem("Statistiche", "/statistiche", otherClasses)}
        <span className="menuSplitter">|</span>
        {renderMenuItem("Scommesse", "/scommesse", otherClasses)}
        <span className="menuSplitter">|</span>
        {renderMenuItem("Live e Risultati", "/live-risultati", "liveAndResults")}
      </>
    )
  }

  const closeFilterMenu = () => {
    setShowBurgerMenu(false)
  }

  return (
    <header className={"calciocom-header" + (showSearchMenu ? " calciocom-header-block-header-div" : "")}>
      {(showBurgerMenu || showSearchMenu) && (
        <div
          className="calciocom-header-background"
          onClick={(e) => {
            e.preventDefault()
            e.stopPropagation()
            setShowBurgerMenu(false)
            setShowSearchMenu(false)
          }}
        />
      )}
      <Container className={`headerMainContainer ${containerClassName}`}>
        <Link href={"/"}>
          <a title={"calcio.com"}>
            <Image src={calciocomLogo} width="170" className="main-logo" height="48" alt="calcio.com" />
            <div className="all-numbers">{"Tutti i numeri del calcio"}</div>
          </a>
        </Link>
        <div className="hs-menu hs-menu-level-1">
          <div className="module-menu">
            <div className="newMenuContainer">
              <ul className="hs-menu--list header-menu-links">{renderMenu()}</ul>
              <GlobalSearchInput desktop close={() => setShowSearchMenu(false)} />
            </div>
          </div>
        </div>
        <div className="hs-menu hs-menu-level-burger" style={{ overflow: "visible" }}>
          <div className="module-menu module-double-menu" style={{ overflow: "visible" }}>
            {showSearchMenu ? null : (
              <ul className="hs-menu--list hs-menu--list-burger">
                <li
                  className="hs-menu--item hs-menu--more hs-menu--item-parent"
                  onClick={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                    return setShowBurgerMenu(!showBurgerMenu)
                  }}
                >
                  {showBurgerMenu ? (
                    <CloseOutlined className="hs-menu--burger-icon" />
                  ) : (
                    <MenuOutlined className="hs-menu--burger-icon" />
                  )}
                  {showBurgerMenu && (
                    <ul className="hs-menu--list hs-menu--list-sub hs-menu--list-more">
                      <div
                        onClick={(e) => {
                          e.preventDefault()
                          e.stopPropagation()
                          return setShowSearchMenu(!showBurgerMenu)
                        }}
                        className="hs-filter-and-search-menu filterOnly"
                      >
                        <FilterSideBar mobile navigationProps={navigationProps!} closeFilterMenu={closeFilterMenu} />
                      </div>
                    </ul>
                  )}
                </li>
              </ul>
            )}
            {showBurgerMenu ? null : (
              <ul className="hs-menu--list hs-menu--list-burger hs-menu--list-search">
                <li
                  className="hs-menu--item hs-menu--more hs-menu--item-parent"
                  onClick={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                    return setShowSearchMenu(!showSearchMenu)
                  }}
                >
                  {showSearchMenu ? (
                    <CloseOutlined className="hs-menu--burger-icon" />
                  ) : showBurgerMenu ? null : (
                    <SearchOutlined className="hs-menu--burger-icon" />
                  )}
                  {showSearchMenu && (
                    <ul className="hs-menu--list hs-menu--list-sub hs-menu--list-more">
                      <div
                        className="hs-filter-and-search-menu"
                        onClick={(e) => {
                          return e.stopPropagation()
                        }}
                      >
                        <GlobalSearchInput close={() => setShowSearchMenu(false)} />
                      </div>
                    </ul>
                  )}
                </li>
              </ul>
            )}
          </div>
        </div>
      </Container>
    </header>
  )
}
