import { NewsType } from "../types/news"

export const NEWS_SLUG_MAP: { [key: string]: NewsType | undefined } = {
  tutte: undefined,
  generali: "basic",
  // trasferimenti: "transfers",
  "pronostici-scommesse": "predictions",
}

export const NEWS_TYPES_STRING: { [key: NewsType | "all"]: string } = {
  all: "Tutte",
  basic: "Generali",
  // transfers: "Trasferimenti",
  predictions: "Pronostici e scommesse",
}
